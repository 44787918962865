/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://m3hygnc27rhvlfklzdfw2zka4a.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-east-1:3beac799-fd24-4ebf-8a7b-1236fb42cc71",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_UZPmLBQjE",
    "aws_user_pools_web_client_id": "3jsdteqcg64dkn297hr7ppe8t3",
    "oauth": {
        "domain": "auth.alumnireach.org",
        "scope": ["email", "openid"],
        "redirectSignIn": "https://auth.alumnireach.org/oauth2/idpresponse",
        "redirectSignOut": "https://auth.alumnireach.org/logout",
        "responseType": "code", 
      },
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": ["GOOGLE"],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
